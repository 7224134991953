import api from "@/http";

function getProductList(query) {
  const { pageNum, pageSize } = query;
  let url =
    "/v1/product/list/page?currPage=" + pageNum + "&pageSize=" + pageSize;
  delete query.pageNum;
  delete query.pageSize;

  Object.keys(query).forEach((item) => {
    if (
      query[item] === null ||
      query[item] === undefined ||
      query[item] === ""
    ) {
      if (!query[item]) delete query[item];
    }
  });

  return api.post(url, { data: query });
}

function getModuleList(params) {
  let url = "/v1/product/module/list/page";
  return api.post(url, { data: params });
}

// 获取产品大类列表
function getProductCategoryList() {
  let url = "/v1/product/category/list";
  return api.post(url, {});
}

// 获取每个大类信息下面对应的小类列表
function getProductCategorySubList(params) {
  let url = "/v1/product/category/sub/list";
  return api.post(url, { data: params });
}

function getBrandList() {
  let url = "/v1/product/brand/list";
  return api.post(url, {});
}

// 获取弹出框中的功能定义列表
function getFunctionDefineList(params) {
  let url = "/v1/product/functiondefine/list";
  return api.post(url, { data: params });
}

// 获取产品详情信息
function getProductDetail(pid) {
  let url = "/v1/product/get/" + pid;
  return api.post(url, {});
}

// 文件上传
function uploadImage(params) {
  let url = "/v1/user/image/upload";
  return api.post(url, { data: params });
}

// 添加配网指引
function addGuide(params) {
  let url = "/v1/product/connectguide/add";
  return api.post(url, { data: params });
}

// 添加产品
function addProduct(params) {
  let url = "/v1/product/add";
  return api.post(url, { data: params });
}

// 更新产品
function updateProduct(params) {
  let url = "/v1/product/update";
  return api.post(url, { data: params });
}

// 添加配网指引
function addGuidance(params) {
  let url = "/v1/product/guidance/add";
  return api.post(url, { data: params });
}

// 更新配网指引
function updateGuidance(params) {
  let url = "/v1/product/guidance/update";
  return api.post(url, { data: params });
}

function getAppList() {
  let url = "/v1/product/appinfo/list";
  return api.post(url, {});
}

function getAppVersionList(id) {
  let url = "/v1/product/appinfo/version/list/" + id;
  return api.post(url, {});
}

// 获取默认情况下的配网信息
function getGuidanceDefault(subCategory) {
  let url = "/v1/product/guidance/get/subcategory/" + subCategory;
  return api.post(url, {});
}

function getGuidance(params) {
  let url = "/v1/product/guidance/get";
  return api.post(url, { data: params });
}

function getGuidanceDetail(pid) {
  let url = "/v1/product/guidance/get/pid/" + pid;
  return api.post(url, {});
}
function getUserList(params) {
  let url = "/v1/product/user/list";
  return api.post(url, { data: params });
}
//用户反馈列表信息
function getFeedBackList(params) {
  let url = "/v1/feedback/info/list/page";
  return api.post(url, { data: params });
}
//更新反馈状态
function updateFeedBackStatus(params) {
  let url = `/v1/feedback/info/update/status?id=${params.id}&status=${params.status}`;
  return api.post(url, {});
}
//更新反馈备注
function updateFeedBackMemo(params) {
  let url = "/v1/feedback/info/update/memo";
  return api.post(url, { data: params });
}

// 产品上线接口
function productOnline(pid) {
  let url = "/v1/product/online/" + pid;
  return api.post(url, {});
}

// 根据产品类型获取该类型已经创建的数据
function getContBySubcategory(subcategory) {
  let url = "/v1/product/category/count/" + subcategory;
  return api.post(url, {});
}

// 设置产品完成硬件联调
function setProductDone(id) {
  let url = "/v1/product/finish/" + id;
  return api.post(url, {});
}

// 删除产品
function productDelete(id) {
  let url = "/v1/product/delete/" + id;
  return api.post(url, {});
}

// 下线
function productOff(id) {
  let url = "/v1/product/offline/" + id;
  return api.post(url, {});
}

export {
  getModuleList,
  getBrandList,
  getFunctionDefineList,
  uploadImage,
  addGuide,
  addProduct,
  updateProduct,
  getProductDetail,
  getProductCategoryList,
  getProductCategorySubList,
  addGuidance,
  updateGuidance,
  getGuidance,
  getAppList,
  getAppVersionList,
  getGuidanceDetail,
  getGuidanceDefault,
  getFeedBackList,
  updateFeedBackStatus,
  updateFeedBackMemo,
  getUserList,
  productOnline,
  getContBySubcategory,
  setProductDone,
  getProductList,
  productDelete,
  productOff,
};
