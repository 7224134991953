import CryptoJS from "crypto-js";

const sessionStg = window.sessionStorage;
const localStg = window.localStorage;

export default {
  getItem(key) {
    try {
      return JSON.parse(sessionStg.getItem(key));
    } catch (err) {
      return null;
    }
  },
  setItem(key, val) {
    sessionStg.setItem(key, JSON.stringify(val));
  },
  clear() {
    sessionStg.clear();
  },
  keys() {
    return sessionStg.keys();
  },
  removeItem(key) {
    sessionStg.removeItem(key);
  },

  getLocalItem(key) {
    const item = localStg.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (err) {
        return item;
      }
    }
    return null;
  },
  setLocalItem(key, val) {
    localStg.setItem(key, JSON.stringify(val));
  },
  clearLocal() {
    localStg.clear();
  },
  keysLocal() {
    return localStg.keys();
  },
  removeLocalItem(key) {
    localStg.removeItem(key);
  },

  encrypSHA256(password, OEM_KEY) {
    return CryptoJS.HmacSHA256(password, OEM_KEY).toString();
  },
};
