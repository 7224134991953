// 业务成功
export const CODE_SUCCESS = 0;

// 令牌缺失
export const CODE_TOKEN_MISSING = 10004;
// 令牌过期
export const CODE_TOKEN_EXPIRED = 12001;
// 令牌无效
export const CODE_TOKEN_INVALID = 12002;
// 用户登出
export const CODE_TOKEN_LOGOUT = 14005;

// 需要重新登录
export const CODE_NEED_LOGIN = 2501110;

// 账号没有权限
export const CODE_NO_AUTH = 2507001;

//用户权限失效，请重新登录,权限变更
export const CODE_PERMISSION_CHANGE = 2501120;

// 接口错误码不弹出Message的列表
export const CODE_NOT_MSG = [
  1101, //产品上线失败
  1104, //上传固件版本号过低
  1107, //上传设备ID文件错误
  10004, // 令牌缺失
  12001, // 令牌过期
  // 12002, // 令牌无效
  14004, // 用户存在，密码错误
  14005, // 用户已登出
  14012, // 用户不存在
];
