import api from "@/http";

// 退出登陆
export function logout() {
  // let url = "/v1/user/logout";
  let url = "/v1/foodmenu/loginout";
  return api.get(url, {});
}

// token续期
export function tokenExtend() {
  let url = "/v1/user/developer/token/extend";
  return api.post(url, {});
}

// 获取用户信息
export function getUserInfo() {
  let url = "/v1/foodmenu/get/userinfo";
  return api.post(url, {});
}

// 获取当前这个用户可以选择的事业部
export function getUserDivision() {
  let url = "/v1/foodmenu/dict/division/list";
  return api.post(url, {});
}
