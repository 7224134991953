<template>
  <div class="header-main">
    <div class="header-bg">
      <!-- <div class="nav"></div> -->
      <!-- <i :class="[collapse?'el-icon-s-unfold':'el-icon-s-fold']"  size="24" @click="handleCollapse"></i> -->
      <el-image
        :src="collapse ? unFold : fold"
        @click="handleCollapse"
        class="fold"
      />
      <div class="nav-content">
        <!-- <div class="nav-logo"></div> -->
        <!-- <OEMMenu /> -->
        <div class="nav-right">
          <!-- <OEMLanguage textColor="textblack" /> -->
          <OEMPortrait />
          <!-- <el-dropdown @command="cliclDepartment">
            <span class="el-dropdown-link">
              {{ userInfo.departName
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in departments"
                :key="item.dataCode"
                :command="item"
                >{{ item.dataValue }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </div>
    <!-- <div style="padding: 13px 32px; font-size: 18px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>运营管理</el-breadcrumb-item>
        <el-breadcrumb-item>菜谱管理</el-breadcrumb-item>
      </el-breadcrumb>
      <h3 style="margin-top: 16px">页面标题区域</h3>
    </div> -->
  </div>
</template>

<script>
// import OEMMenu from "../OEMMenu/index";
import OEMLanguage from "../OEMLanguage/index.vue";
import OEMPortrait from "../OEMPortrait/index.vue";
import { getUserInfo } from "@/api/user";
import { getSelect } from "@/api/menu";
export default {
  name: "OEMHeader",
  components: {
    // OEMMenu,
    OEMLanguage,
    OEMPortrait,
  },
  data() {
    return {
      unFold: require("@/assets/images/menu/unFold.png"),
      fold: require("@/assets/images/menu/fold.png"),
      userInfo: {},
      departments: [],
    };
  },
  watch: {
    $route(to, from) {
      // console.log(to);
      // console.log(from);
    },
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
  },
  created() {
    // {"id":3,"uid":"zhaobl12","name":"***龙","departName":"美的_美的集团_AI创新中心_国际智能化_开发",
    // "status":1,"ldapInfo":null,"email":"zhaobl12@midea.com","createTime":1684396621000,
    // "updateTime":1684396621000,"casAccessToken":"AT-89-wbdBEwvargEH6OI6X5FAeEKbFnGFeO7TKJa"}
    // getUserInfo().then((res) => {
    //   this.userInfo = res;
    // });
    // getSelect({
    //   dataType: ["RECIPE_SOURCE"],
    // }).then((res) => {
    //   for (const key in res) {
    //     res[key] = res[key].map((item) => {
    //       try {
    //         const json = JSON.parse(item.dataValue);
    //         return {
    //           dataCode: item.dataCode,
    //           dataValue: json.zh_CN ? json.zh_CN : json,
    //         };
    //       } catch {
    //         return item;
    //       }
    //     });
    //   }
    //   this.departments = res.RECIPE_SOURCE;
    // });
  },
  methods: {
    handleCollapse() {
      const collapse = this.$store.state.collapse;
      this.$store.commit("setCollapse", !collapse);
    },
    cliclDepartment(res) {
      console.log(res);
      this.userInfo.departName = res.dataValue;
    },
  },
};
</script>

<style lang="less" scoped>
.header-main {
  width: 100%;
  position: fixed;
  right: 0px;
  z-index: 6;
}

.header-bg {
  width: 100%;
  height: 48px;
  // box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  position: relative;
  background: #fff;
  .fold {
    position: absolute;
    top: 10px;
    left: 225px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    cursor: pointer;
    z-index: 100;
  }
}

.nav-content {
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.nav-right {
  display: flex;
}
.el-dropdown {
  display: flex;
  align-items: center;
}
</style>
