<template>
  <div class="logo">
    <el-image :src="collapse?logoVertical:logo" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Logo",
  computed:{
    collapse(){
      return this.$store.state.collapse
    }
  },
  data(){
    return {
      logoVertical:require('@/assets/images/logo_vertical.png'),
      logo:require('@/assets/images/logo.png')
    }
  }
};
</script>

<style lang="less" scoped>
.logo {
  display: flex;
  align-items: center;
}
.el-image {
  padding: 0;
  font-size: 0;
  // border: 1px solid green;
}
</style>
