<template>
  <div class="menu-main" >
    <div v-if="permitCodes === null && isAdmin === 0"></div>
    <el-menu
      v-else
      @select="handleSelect"
      ref="menu"
      class="menu-list"
      background-color="transparent"
      text-color="#fff"
      active-text-color="#fff"
      style="border: none"
      unique-opened
      :collapse-transition="false"
      :collapse="collapse"
      :default-active="activeIndex"
    >
      <template v-for="item in navMenuListFilter">
        <template v-if="item.submenu">
          <OEMSubMenu :key="item.name" :menu="item" :index="item.path" />
        </template>
        <el-menu-item :key="item.name" :index="item.path" v-else>
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            <img
              :src="require(`@/assets/images/menu/${item.icon}.png`)"
              style="height: 18px; width: 18px; margin-right: 10px"
            />
            <span>{{ item.name }}</span>
          </div>
          <i
            class="el-submenu__icon-arrow el-icon-arrow-right"
            v-if="item.submenu"
          ></i>
        </el-menu-item>
      </template>
    </el-menu>
  </div> 
    <!-- todo: 使用router-link渲染报错
    Cannot read properties of undefined (reading 'resolve') -->
    <!-- <router-link to="/">Go to Home</router-link> -->
</template>

<script>
import Storage from "@/utils/Storagehandle";
// import { delCookie, setCookie, getCookie } from "@/utils/CookieUtils";
import { navMenuList } from "@/utils/index";
import { filterByPermitCode } from "@/utils/commonFunc";
import { getUserInfo } from "@/api/user";
export default {
  name: "OEMMenu",
  data() {
    return {
      activeIndex: "/operator/#/menu/list",
      currentName: "",
      navMenuList: navMenuList,
      navMenuListFilter: navMenuList,
      permitCodes:null,
      isAdmin:''
    };
  },
  watch: {
    $route: {
      // 刷新页面的时候，可能会丢掉激活菜单的样式，所以这里要特殊处理
      // 这里判断用户是否有权限进入指定路由，防止用户复制链接进入
      handler(newValue, oldValue) {
        console.log("=====>", newValue,this.$store.state);
        // const divisionId = this.$store.state.divisionId;
        // const _subPath = newValue.path.split("/").filter((_K) => _K);
        // console.log("_subPath", _subPath);
        let targetPath = newValue.fullPath;
        // if (_subPath.includes("menu")) {
        //   //食谱管理板块
        //   if (_subPath.includes("product")) {
        //     targetPath = "product/list";
        //   } else {
        //     targetPath = "menu/list";
        //   }
        // } else if (_subPath.includes("product")) {
        //   //产品管理板块
        //   targetPath = "product/list";
        // } else if (_subPath.includes("class")) {
        //   //分类管理板块
        //   if (_subPath.includes("stuff")) {
        //     targetPath = "stuff/class/list";
        //   } else {
        //     targetPath = "class/list";
        //   }
        // } else if (_subPath.includes("tag")) {
        //   //标签管理板块
        //   targetPath = "tag/list";
        // } else if (_subPath.includes("unit")) {
        //   //食材列表板块
        //   targetPath = "stuff/unit/list";
        // } else if (_subPath.includes("info")) {
        //   //食材单位板块
        //   targetPath = "stuff/info/list";
        // } else if (_subPath.includes("religion")) {
        //   //宗教板块
        //   targetPath = "stuff/religion/list";
        // } else if (_subPath.includes("allergy")) {
        //   //过敏源板块
        //   targetPath = "stuff/allergy/list";
        // }
        // console.log('targetPath',targetPath)
        this.activeIndex = "/operator/#" + targetPath;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
    // 动态计算左侧导航栏菜单，只有超级管理员的时候，才显示账号权限管理和帮助中心管理
    // navMenuListFilter() {
    //   // const divisionId = getCookie("divisionId");
    //   // console.log("====", this.$store.state.account);
    //   // console.log(
    //   //   "#####################--navMenuListFilter",
    //   //   this.$store.state.permitCodes
    //   // );

    //   // console.log(this.navMenuList);
    //   let nav = filterByPermitCode(this.navMenuList);
    //   console.log(nav, "nav------------------navMenuListFilter-");
    //   if (this.$store.state.account.divisionId === "0") {
    //     return nav;
    //   } else {
    //     return nav.filter((f) => f.name !== "帮助中心管理");
    //   }
    // },
  },
  created() {

      getUserInfo()
      .then((res) => {
        console.log("#####################--getUserInfo",res, res.permitCodes,res.isAdmin);
        this.permitCodes = res.permitCodes
        this.isAdmin = res.isAdmin
        Storage.setLocalItem("permitCodes", res.permitCodes);
        Storage.setLocalItem("isAdmin", res.isAdmin);
      })
      .then(() => {
        let nav = filterByPermitCode(
          this.navMenuList,
          Storage.getLocalItem("permitCodes"),
          Storage.getLocalItem("isAdmin")
        );
        console.log(nav, "nav-------------------getUserInfo");
        //处理slot缺失，动态补足slot
        let foodIngredients =nav.find((p) => p.name === "食材管理"); // 找到食材管理
        if (foodIngredients) {
          if (foodIngredients.submenu) {
            let dictionary = foodIngredients.submenu.find(
              (p) => p.slot === "字典"
            ); // 找到食材管理
            if (!dictionary) {
              let firstNode = foodIngredients.submenu.find((i) => !i.slot);
              firstNode.slot = "字典";
            }
          }
        }
        if (this.$store.state.account.divisionId === "0") {
          this.navMenuListFilter = nav;
        } else {
          this.navMenuListFilter = nav.filter((f) => f.name !== "帮助中心管理");
        }
      });
    // console.log(this.$route);
    // operator/#/menu/list
    // const path = "/operator/#" + this.$route.fullPath;
    // this.activeIndex = path;
    // fullPath: "/product/list"
    // this.currentPath = this.$route.path;
    // let currentRoute = Storage.getItem("currentRoute");
    // if (currentRoute) {
    //   this.currentName = currentRoute.name;
    // } else {
    //   this.currentName = "产品管理";
    // }
  },
  methods: {
    handleSelect(item, keyPath) {
      console.log(item, keyPath);
      history.pushState(null, item, item);
    },

    setActive(path) {
      this.$refs.menu && this.$refs.menu.updateActiveIndex(path);
    },
    // todo: main中使用的store，是子应用的store，main中是否可以有自己的store
    // goTo(item) {
    //   console.log(this.$store);
    //   this.currentName = item.name;
    //   Storage.setItem("currentRoute", item);
    //   history.pushState(null, item.path, item.path);
    // },
  },
};
</script>

<style lang="less" scoped>
.menu-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px !important;
  font-size: 16px;
  font-weight: 500;
}
.menu-list .el-menu {
  background-color: rgba(0, 0, 0, 0.42) !important;
}
.el-menu--vertical {
  background-color: #fff !important;
  left: 78px !important;
  .el-menu--popup {
    padding: 0 5px;
    margin: 0;
    background-color: #fff !important;
  }
  .el-menu-item {
    color: #232323 !important;
  }
}
// 菜单收纳起来的样式
.el-menu--collapse {
  width: 42px !important;
  > li.el-menu-item {
    padding-left: 13px !important;
    // padding-right: 11px !important;
  }
  /deep/ li.el-submenu {
    // padding-left: 0 !important;
    // // padding-right: 11px !important;
    .el-submenu__title {
      padding-left: 13px !important;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
  // 收纳菜单弹出样式
  /deep/ .el-menu--popup-right-start {
    background: #fff !important;
    overflow: hidden;
    .el-menu-item {
      color: #232323 !important;
    }
    .el-menu-item:hover {
      color: #409eff !important;
    }
    .el-menu-item.is-active {
      color: #fff !important;
    }
  }
}
.el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  margin: 12px 0;
  width: 100%;
  min-width: auto !important;
  border: none;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  // justify-content: space-between;
  align-items: center;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #409eff !important;
  background: transparent !important;
}
.el-menu-item.is-active {
  color: #fff !important;
  // background: #409eff !important;
  background: linear-gradient(to right, #1bb3ff, #208cff) !important;
  border-radius: 8px;
}
.arrow-right {
  height: 40px;
  width: 40px;
  display: inline-block;
  position: relative;
}
.arrow-right::after {
  content: "";
  height: 10px;
  width: 10px;
  top: 12px;
  right: 0;
  border-width: 1px 1px 0 0;
  border-color: #fff;
  border-style: solid;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  position: absolute;
}
.el-submenu {
  width: 100%;
}

.el-submenu__title:hover {
  background-color: transparent !important;
  color: #409eff !important;
}
.el-submenu .el-menu-item {
  padding-left: 55px !important;
}
.el-submenu__icon-arrow::before {
  color: #ffff !important;
  font-size: 16px;
}
.el-submenu__icon-arrow {
  display: inline-block;
  // background: red;
  margin-top: -9px !important;
  // transform: translateY(-50%);
  transform: rotateZ(270deg);
}
// .el-icon-arrow-down::before {
//   content: "\e6e0" !important;
// }

.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  transform: rotateZ(360deg) !important;
}
</style>
