<template>
  <div class="portrait-main">
    <el-popover placement="bottom" :visible-arrow="false">
      <div style="display: -webkit-flex; flex-direction: row" slot="reference">
        <img :src="account.headPhoto" class="portrait" />
        <div class="user-name" style="align-self: center">
          {{ userInfo.name }}
        </div>
      </div>

      <div style="width: 248px; padding: 8px">
        <div class="user-detail">
          <img :src="account.headPhoto" class="detail-portrait" />
          <div>
            <div class="detail-user-name">{{ userInfo.name }}</div>
            <div class="detail-user-email">{{ userInfo.email }}</div>
          </div>
        </div>

        <el-popover width="260" placement="left" trigger="hover">
          <div>
            <div
              v-for="item in languages"
              :key="item.dataCode"
              :class="[
                'detail-item',
                current_language === item.dataCode && 'detail-item-active',
              ]"
            >
              <span>{{ item.dataValue }}</span>
              <i class="el-icon-check"></i>
            </div>
          </div>
          <div class="menu-item" slot="reference">
            <div class="menu-item_left">
              <OEMIcon icon="earth" /><span class="menu-item_text">语言</span>
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </el-popover>
        <el-popover width="260" placement="left" trigger="hover">
          <div>
            <div
              v-for="item in userInfo.divisionList"
              @click="changeDepart(item)"
              :key="item.dataCode"
              :class="[
                'detail-item',
                current_division == item.dataCode && 'detail-item-active',
              ]"
            >
              <span>{{ item.dataValue }}</span>
              <i
                class="el-icon-check"
                v-if="current_division == item.dataCode"
              ></i>
            </div>
          </div>
          <div class="menu-item" slot="reference">
            <div class="menu-item_left">
              <OEMIcon icon="lock" /><span class="menu-item_text"
                >权限切换</span
              >
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </el-popover>
        <div class="menu-item" @click="goHelp">
          <div class="menu-item_left">
            <OEMIcon icon="question" /><span class="menu-item_text"
              >帮助中心</span
            >
          </div>
          <div></div>
        </div>
        <div class="menu-item" @click="logout" style="border: none">
          <div class="menu-item_left">
            <OEMIcon icon="exit" /><span class="menu-item_text">退出登录</span>
          </div>
          <div></div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSelect } from "@/api/menu";
import { logout, getUserInfo, getUserDivision } from "@/api/user";
import { delCookie, setCookie, getCookie } from "@/utils/CookieUtils";
import Storage from "@/utils/Storagehandle";
import Vue from "vue";
export default {
  name: "OEMPortrait",
  computed: mapState({
    account(state) {
      return state.account.account;
    },
  }),
  data() {
    return {
      showDetailContent: false,
      userInfo: {},
      departments: [],
      languages: [],
      current_division: "",
      current_language: "zh_CN",
    };
  },
  created() {
    // 从cookie中获取当前选择的事业部id
    this.current_division = getCookie("divisionId");

    getUserInfo().then((res) => {
      res.divisionList = res.divisionList.map((item) => {
        try {
          const json = JSON.parse(item.divisionName);
          return {
            dataCode: item.divisionId,
            dataValue: json.zh_CN ? json.zh_CN : json,
          };
        } catch {
          return item;
        }
      });
      this.userInfo = res;
      Storage.setLocalItem("email", res.email);
      Storage.setLocalItem("uid", res.uid);
      Storage.setLocalItem("permitCodes", res.permitCodes);
      Storage.setLocalItem("isAdmin", res.isAdmin);
      this.$store.commit("account/setDivisionId", res.divisionId.toString());

      // this.departments = res;
    });

    // getUserDivision().then((res) => {
    //   console.log(res);

    //   res = res.map((item) => {
    //     try {
    //       const json = JSON.parse(item.dataValue);
    //       return {
    //         dataCode: item.dataCode,
    //         dataValue: json.zh_CN ? json.zh_CN : json,
    //       };
    //     } catch {
    //       return item;
    //     }
    //   });

    //   this.departments = res;
    // });

    getSelect({
      dataType: ["RECIPE_SOURCE", "LANGUAGE"],
    }).then((res) => {
      for (const key in res) {
        res[key] = res[key].map((item) => {
          try {
            const json = JSON.parse(item.dataValue);
            return {
              dataCode: item.dataCode,
              dataValue: json.zh_CN ? json.zh_CN : json,
            };
          } catch {
            return item;
          }
        });
      }
      this.departments = res.RECIPE_SOURCE;
      this.languages = [
        {
          dataCode: "zh_CN",
          dataValue: "简体中文",
        },
      ];
    });
  },
  methods: {
    logout() {
      logout()
        .then((res) => {
          console.log(res);
          delCookie("accessToken"); // 删除本地Cookie

          // 登出的地址，只分生产环境和非生产环境
          let redirect_uri = window.location.origin;
          let host = "";
          if (
            window.location.host === "recipe.dollin.net" ||
            window.location.host === "uat-recipe.dollin.net"
          ) {
            host = "https://signin.midea.com";
          } else {
            host = "https://signinuat.midea.com";
          }
          window.location.href = `${host}/logout?service=${redirect_uri}`;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goHelp() {
      // console.log(window.location.host);
      const url = window.location.origin + "/#/help";
      // window.open(url, "_blank");
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      // window.location.href = `${host}/logout?service=${redirect_uri}`;
    },
    // 更改事业部,

    changeDepart(item) {
      console.log(item);
      this.current_division = item.dataCode;
      setCookie("divisionId", item.dataCode); // 更改事业部id
      Storage.removeItem("tableConfig"); //清空缓存的 表头字段
      this.$store.commit("account/setDivisionId", item.dataCode.toString());
      const url = window.location.origin + "/operator/#/menu/list";
      window.location.replace(url);
      getUserInfo()
        .then((res) => {
          Storage.setLocalItem("permitCodes", res.permitCodes);
          Storage.setLocalItem("isAdmin", res.isAdmin);
        })
        .then(() => {
          window.location.reload();
        });
      // 更改事业部后，需要强刷数据，
      // 因为很多接口需要依赖缓存的数据，而缓存的数据跟事业部有关系
    },
  },
};
</script>

<style lang="less" scoped>
.portrait-main {
  // height: 30px;
  // margin-top: 11px;
  border-left: 1px solid #ffffff26;
  display: flex;
  align-items: center;
  margin-right: 24px;
  position: relative;
}

.portrait {
  width: 30px;
  height: 30px;
  background-image: url("../../assets/images/ic_n_portrait_s@2x.png");
  background-size: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.user-name {
  min-width: 47px;
  height: 22px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.user-detail {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 18px;
}

.detail-portrait {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}

.detail-user-name {
  height: 16px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #333;
  line-height: 16px;
  font-weight: 400;
  margin-top: 3px;
}

.detail-user-email {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  font-weight: 400;
  line-height: 22px;
  font-weight: 400;
  padding-top: 4px;
}

.detail-item {
  padding: 0 20px;
  line-height: 38px;
  white-space: nowrap;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  &-active {
    font-size: 14px;
    color: #267aff;
    font-weight: 400;
  }
}

.menu-item {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  text-align: center;
  line-height: 48px;
  font-weight: 400;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_left {
    display: flex;
    align-items: center;
  }
  &_text {
    padding-left: 6px;
  }
}
</style>
