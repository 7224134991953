export const ROLE_PERMISSION_DATA = {
  GROUP_MANAGE: "group_manage", // 1: 分组管理
  INGREDIENT_MANAGE: "ingredient_manage", // 3: 食材管理
  MENU_MANAGE: "menu_manage", // 4: 食谱管理
  PRODUCT_MANAGE: "product_manage", // 5: 产品管理
  PERMIT_MANAGE: "permit_manage", // 6: 权限管理
  CATEGORY_MANAGE: "category_manage", // 7: 分类管理
  TAG_MANAGE: "tag_manage", // 8: 标签管理
  CATEGORY_MANAGE_OBM: "category_manage_obm", // 9: OBM
  CATEGORY_MANAGE_OEM: "category_manage_oem", // 10: OEM
  CATEGORY_MANAGE_OBM_LIST: "category_manage_obm_list", // 11: 列表-查看
  CATEGORY_MANAGE_OBM_SEARCH: "category_manage_obm_search", // 12: 搜索
  CATEGORY_MANAGE_OBM_ADD: "category_manage_obm_add", // 13: 创建
  CATEGORY_MANAGE_OBM_ORDER: "category_manage_obm_order", // 14: 排序
  CATEGORY_MANAGE_OBM_DELETE: "category_manage_obm_delete", // 15: 删除
  CATEGORY_MANAGE_OBM_ONLINE: "category_manage_obm_online", // 16: 上线
  CATEGORY_MANAGE_OBM_OFFLINE: "category_manage_obm_offline", // 17: 下线
  CATEGORY_MANAGE_OBM_DETAIL: "category_manage_obm_detail", // 18: 详情-查看
  CATEGORY_MANAGE_OBM_DETAIL_UPDATE: "category_manage_obm_detailUpdate", // 19: 详情-编辑
  CATEGORY_MANAGE_OBM_RELMENU: "category_manage_obm_relMenu", //关联食谱查看
  CATEGORY_MANAGE_OBM_RELMENUUPDATE: "category_manage_obm_relMenuUpdate", //关联食谱-编辑
  CATEGORY_MANAGE_OBM_RELPRODUCT: "category_manage_obm_relProduct", //关联产品-查看
  CATEGORY_MANAGE_OEM_LIST: "category_manage_oem_list", // 20: 列表-查看
  CATEGORY_MANAGE_OEM_SEARCH: "category_manage_oem_search", // 21: 搜索
  CATEGORY_MANAGE_OEM_ADD: "category_manage_oem_add", // 22: 创建
  CATEGORY_MANAGE_OEM_ORDER: "category_manage_oem_order", // 23: 排序
  CATEGORY_MANAGE_OEM_DELETE: "category_manage_oem_delete", // 24: 删除
  CATEGORY_MANAGE_OEM_ONLINE: "category_manage_oem_online", // 25: 上线
  CATEGORY_MANAGE_OEM_OFFLINE: "category_manage_oem_offline", // 26: 下线
  CATEGORY_MANAGE_OEM_DETAIL: "category_manage_oem_detail", // 27: 详情-查看
  CATEGORY_MANAGE_OEM_DETAIL_UPDATE: "category_manage_oem_detailUpdate", // 28: 详情-编辑
  CATEGORY_MANAGE_OEM_RELMENU: "category_manage_oem_relMenu", //关联食谱查看
  CATEGORY_MANAGE_OEM_RELMENUUPDATE: "category_manage_oem_relMenuUpdate", //关联食谱-编辑
  CATEGORY_MANAGE_OEM_RELPRODUCT: "category_manage_oem_relProduct", //关联产品-查看
  TAG_MANAGE_LIST: "tag_manage_list", // 29: 列表-查看
  TAG_MANAGE_SEARCH: "tag_manage_search", // 30: 搜索
  TAG_MANAGE_ADD: "tag_manage_add", // 31: 创建
  TAG_MANAGE_ORDER: "tag_manage_order", // 32: 排序
  TAG_MANAGE_STATUS: "tag_manage_status", // 33: 启用/禁用
  TAG_MANAGE_DELETE: "tag_manage_delete", // 34: 删除
  TAG_MANAGE_DETAIL: "tag_manage_detail", // 35: 详情-查看
  TAG_MANAGE_DETAIL_UPDATE: "tag_manage_detailUpdate", // 36: 详情-编辑
  TAG_MANAGE_MENU_TAG_ADD: "tag_manage_menuTagAdd", //食谱-新增标签
  INGREDIENT_INFO: "ingredient_info", // 37: 食材列表
  INGREDIENT_CATEGORY: "ingredient_category", // 38: 食材分类
  TABOO_RELIGION: "taboo_religion", // 39: 禁忌宗教
  ALLERGEN: "allergen", // 40: 过敏源
  INGREDIENT_UNIT: "ingredient_unit", // 41: 食材单位
  INGREDIENT_LIST: "ingredient_list", // 44: 列表-查看
  INGREDIENT_SEARCH: "ingredient_search", // 45: 搜索
  INGREDIENT_ADD: "ingredient_add", // 46: 创建
  INGREDIENT_STATUS: "ingredient_status", // 47: 启用/禁用
  INGREDIENT_DELETE: "ingredient_delete", // 49: 删除
  INGREDIENT_DETAIL: "ingredient_detail", // 50: 详情-查看
  INGREDIENT_DETAIL_UPDATE: "ingredient_detailUpdate", // 51: 详情-编辑
  NUTRITIONAL_INFO_DETAIL: "nutritional_info_detail", // 52: 营养信息-查看
  NUTRITIONAL_INFO_DETAIL_UPDATE: "nutritional_info_detailUpdate", // 53: 营养信息-编辑
  INGREDIENT_STORAGE_DETAIL: "ingredient_storage_detail", // 54: 食材储存-查看
  INGREDIENT_STORAGE_DETAIL_UPDATE: "ingredient_storage_detailUpdate", // 55: 食材储存-编辑
  INGREDIENT_CATEGORY_LIST: "ingredient_category_list", // 56: 列表-查看
  INGREDIENT_CATEGORY_SEARCH: "ingredient_category_search", // 57: 搜索
  INGREDIENT_CATEGORY_ADD: "ingredient_category_add", // 58: 创建
  INGREDIENT_CATEGORY_DELETE: "ingredient_category_delete", // 59: 删除
  INGREDIENT_CATEGORY_DETAIL: "ingredient_category_detail", // 60: 详情-查看
  INGREDIENT_CATEGORY_DETAIL_UPDATE: "ingredient_category_detailUpdate", // 61: 详情-编辑
  TABOO_RELIGION_LIST: "taboo_religion_list", // 63: 列表-查看
  TABOO_RELIGION_SEARCH: "taboo_religion_search", // 64: 搜索
  TABOO_RELIGION_ADD: "taboo_religion_add", // 65: 创建
  TABOO_RELIGION_DELETE: "taboo_religion_delete", // 66: 删除
  TABOO_RELIGION_DETAIL: "taboo_religion_detail", // 67: 详情-查看
  TABOO_RELIGION_DETAIL_UPDATE: "taboo_religion_detailUpdate", // 68: 详情-编辑
  ALLERGEN_LIST: "allergen_list", // 69: 列表-查看
  ALLERGEN_SEARCH: "allergen_search", // 70: 搜索
  ALLERGEN_ADD: "allergen_add", // 71: 创建
  ALLERGEN_DELETE: "allergen_delete", // 72: 删除
  ALLERGEN_DETAIL: "allergen_detail", // 73: 详情-查看
  ALLERGEN_DETAIL_UPDATE: "allergen_detailUpdate", // 74: 详情-编辑
  INGREDIENT_UNIT_LIST: "ingredient_unit_list", // 75: 列表-查看
  INGREDIENT_UNIT_SEARCH: "ingredient_unit_search", // 76: 搜索
  INGREDIENT_UNIT_ADD: "ingredient_unit_add", // 77: 创建
  INGREDIENT_UNIT_DELETE: "ingredient_unit_delete", // 78: 删除
  INGREDIENT_UNIT_DETAIL: "ingredient_unit_detail", // 79: 详情-查看
  INGREDIENT_UNIT_DETAILUPDATE: "ingredient_unit_detailUpdate", // 80: 详情-编辑
  MENU_LIST: "menu_list", // 81: 列表-查看
  MENU_SEARCH: "menu_search", // 83: 食谱搜索
  MENU_TABLE_HEADER: "menu_tableHeader", // 84: 表头筛选
  MENU_EXPORT_RECORD: "menu_exportRecord", // 85: 导出记录
  MENU_ADD: "menu_add", // 86: 新增食谱
  MENU_BATCH: "menu_batch", // 87: 批量操作
  MENU_FUNCTION: "menu_function", // 88: 食谱操作
  MENU_BATCH_ONLINE: "menu_batch_online", // 89: 批量上线
  MENU_BATCH_OFFLINE: "menu_batch_offline", // 90: 批量下线
  MENU_BATCH_COPY: "menu_batch_copy", // 91: 批量复制
  MENU_BATCH_DELETE: "menu_batch_delete", // 92: 批量删除
  MENU_BATCH_DELETE_KONG: "menu_batch_deleteKong", // 93: 批量删控
  MENU_BATCH_IMPORT: "menu_batch_import", // 94: 批量导入
  MENU_BATCH_EXPORT: "menu_batch_export", // 95: 批量导出
  MENU_BATCH_FILTER: "menu_batch_filter", // 96: 批量灰度
  MENU_BATCH_IMPORT_TABLE: "menu_batch_import_table", // 97: 食谱详情表导入
  MENU_BATCH_IMPORT_REL_PRO: "menu_batch_import_relPro", // 98: 关联产品
  MENU_BATCH_EXPORT_DETAIL: "menu_batch_export_detail", // 99: 食谱详情
  MENU_BATCH_EXPORT_ORDER: "menu_batch_export_order", // 100: 电控文档
  MENU_BATCH_EXPORT_MEDIA: "menu_batch_export_media", // 101: 媒体文件
  MENU_BATCH_IMPORT_BATCHORDER: "menu_batch_import_batchOrder", //批量电控
  PRODUCT_MANAGE_OBM: "product_manage_obm", // 104: OBM
  PRODUCT_MANAGE_OEM: "product_manage_oem", // 105: OEM
  PRODUCT_OBM_LIST: "product_obm_list", // 106: 列表-查看
  PRODUCT_OBM_SEARCH: "product_obm_search", // 107: 搜索
  PRODUCT_OBM_COUNTRY: "product_obm_country", // 108: 国家
  PRODUCT_OBM_MENU: "product_obm_menu", // 109: 产品食谱
  PRODUCT_OBM_REL_PRO: "product_obm_relPro", // 110: 关联产品
  PRODUCT_OBM_VERSION: "product_obm_version", // 111: 电控固件版本
  PRODUCT_OBM_COUNTRY_DETAIL: "product_obm_country_detail", // 112: 查看
  PRODUCT_OBM_COUNTRY_DETAIL_UPDATE: "product_obm_country_detailUpdate", // 113: 编辑
  PRODUCT_OBM_MENU_DETAIL: "product_obm_menu_detail", // 114: 查看
  PRODUCT_OBM_MENU_DETAIL_UPDATE: "product_obm_menu_detailUpdate", // 115: 编辑
  PRODUCT_OBM_REL_PRO_DETAIL: "product_obm_relPro_detail", // 116: 查看
  PRODUCT_OBM_REL_PRO_DETAIL_UPDATE: "product_obm_relPro_detailUpdate", // 117: 编辑
  PRODUCT_OBM_VERSION_DETAIL: "product_obm_version_detail", // 118: 查看
  PRODUCT_OBM_VERSION_DETAIL_UPDATE: "product_obm_version_detailUpdate", // 119: 编辑
  PERMIT_MANAGE_USER: "permit_manage_user", // 120: 账号权限管理
  PERMIT_MANAGE_ROLE: "permit_manage_role", // 121: 角色管理
  PERMIT_MANAGE_WHITELIST: "permit_manage_whiteList", // 122: 灰度白名单
  PERMIT_USER_LIST: "permit_user_list", // 123: 列表-查看
  PERMIT_USER_SEARCH: "permit_user_search", // 124: 搜索
  PERMIT_USER_ADD: "permit_user_add", // 125: 创建
  PERMIT_USER_UPDATE: "permit_user_update", // 126: 编辑
  PERMIT_USER_STATUS: "permit_user_status", // 127: 启用/禁用
  PERMIT_ROLE_LIST: "permit_role_list", // 128: 列表-查看
  PERMIT_ROLE_SEARCH: "permit_role_search", // 129: 搜索
  PERMIT_ROLE_ADD: "permit_role_add", // 130: 创建
  PERMIT_ROLE_UPDATE: "permit_role_update", // 131: 编辑
  PERMIT_ROLE_STATUS: "permit_role_status", // 132: 启用/禁用
  PERMIT_WHITELIST_LIST: "permit_whiteList_list", // 133: 列表-查看
  PERMIT_WHITELIST_SEARCH: "permit_whiteList_search", // 134: 搜索
  PERMIT_WHITELIST_ADD: "permit_whiteList_add", // 135: 创建
  PERMIT_WHITELIST_STATUS: "permit_whiteList_status", // 136: 启用/禁用
  PERMIT_WHITELIST_DELETE: "permit_whiteList_delete", // 137: 删除
  PERMIT_WHITELIST_DETAIL: "permit_whiteList_detail", // 138: 详情-查看
  PERMIT_WHITELIST_DETAIL_UPDATE: "permit_whiteList_detailUpdate", // 139: 详情-编辑
  PRODUCT_OEM_LIST: "product_oem_list", // 140: 列表-查看
  PRODUCT_OEM_SEARCH: "product_oem_search", // 141: 搜索
  PRODUCT_OEM_COUNTRY: "product_oem_country", // 142: 国家
  PRODUCT_OEM_MENU: "product_oem_menu", // 143: 产品食谱
  PRODUCT_OEM_REL_PRO: "product_oem_relPro", // 144: 关联产品
  PRODUCT_OEM_VERSION: "product_oem_version", // 145: 电控固件版本
  PRODUCT_OEM_COUNTRY_DETAIL: "product_oem_country_detail", // 146: 查看
  PRODUCT_OEM_COUNTRY_DETAIL_UPDATE: "product_oem_country_detailUpdate", // 147: 编辑
  PRODUCT_OEM_MENU_DETAIL: "product_oem_menu_detail", // 148: 查看
  PRODUCT_OEM_MENU_DETAIL_UPDATE: "product_oem_menu_detailUpdate", // 149: 编辑
  PRODUCT_OEM_REL_PRO_DETAIL: "product_oem_relPro_detail", // 150: 查看
  PRODUCT_OEM_REL_PRO_DETAIL_UPDATE: "product_oem_relPro_detailUpdate", // 151: 编辑
  PRODUCT_OEM_VERSION_DETAIL: "product_oem_version_detail", // 152: 查看
  PRODUCT_OEM_VERSION_DETAIL_UPDATE: "product_oem_version_detailUpdate", // 153: 编辑
  MENU_FUNCTION_ORDER: "menu_function_order", // 154: 排序
  MENU_FUNCTION_EDIT: "menu_function_edit", // 155: 编辑
  MENU_FUNCTION_UPDATE: "menu_function_update", // 156: 更新
  MENU_FUNCTION_ONLINE: "menu_function_online", // 157: 上线
  MENU_FUNCTION_OFFLINE: "menu_function_offline", // 158: 下线
  MENU_FUNCTION_REJECT: "menu_function_reject", // 159: 驳回
  MENU_FUNCTION_PUBLISH: "menu_function_publish", // 159: 发布
  MENU_FUNCTION_MEDIA: "menu_function_media", // 160: 媒体文件
  MENU_FUNCTION_COPY: "menu_function_copy", // 161: 复制
  MENU_FUNCTION_DELETE: "menu_function_delete", // 162: 删除
  MENU_FUNCTION_DETAIL: "menu_function_detail", // 163: 查看详情
  MENU_FUNCTION_FILTER: "menu_function_filter", // 164: 灰度
  MENU_FUNCTION_DETAIL_BASE: "menu_function_detail_base", // 165: 基本信息
  MENU_FUNCTION_DETAIL_INFO: "menu_function_detail_info", // 166: 食谱信息
  MENU_FUNCTION_DETAIL_ORDER: "menu_function_detail_order", // 167: 电控指令
  MENU_FUNCTION_FILTER_PUBLISH: "menu_function_filter_publish", // 168: 灰度发布
  MENU_FUNCTION_FILTER_SYNC: "menu_function_filter_sync", // 169: 确定同步
};
