<template>
  <el-submenu :index="menu.name" :popper-append-to-body="false">
    <template slot="title">
      <div style="display: flex; flex-wrap: nowrap; align-items: center">
        <img
          :src="require(`@/assets/images/menu/${menu.icon}.png`)"
          style="height: 18px; width: 18px; margin-right: 10px"
        />
        <span>{{ menu.name }}</span>
      </div>
    </template>
    <template
      v-for="(sub, index) in menu.submenu.filter(
        (f) =>
          f.division === undefined ||
          f.division.includes($store.state.account.divisionId)
      )"
    >
      <el-menu-item-group :key="sub.name + index" class="subMenu">
        <span slot="title" v-if="sub.slot" :class="index === 0 && 'slot'">{{
          sub.slot
        }}</span>
        <!-- <template v-if="sub.submenu">
          <OEMSubMenu :index="sub.path" :menu="sub" />
        </template> -->
        <el-menu-item :index="sub.path">
          <div style="display: flex; flex-wrap: nowrap; align-items: center">
            <span>{{ sub.name }}</span>
          </div>
        </el-menu-item>
      </el-menu-item-group>
    </template>
  </el-submenu>
</template>
<script>
export default {
  name: "OEMSubMenu",
  props: {
    menu: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created(){
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.el-menu-item {
  height: auto !important;
  line-height: 40px !important;
  margin: 12px 10px;
  width: 100%;
  border: none;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #409eff !important;
  background: transparent !important;
}

.el-menu-item.is-active {
  color: #fff !important;
  // background: #409eff !important;
  background: linear-gradient(to right, #1bb3ff, #208cff) !important;
  border-radius: 8px;
}

.subMenu {
  /deep/ .el-menu-item-group__title {
    padding: 0 !important;
    padding-left: 30px !important;
  }
  /deep/ .slot {
    margin-top: 10px;
    display: inline-block;
  }
}
</style>
<style>
.el-submenu__title:hover {
  color: #409eff !important;
  background-color: transparent !important;
}
</style>
