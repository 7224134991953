const CryptoJS = require("crypto-js");
//设置cookie
export function setCookie(c_name, value, expire) {
  let date = new Date();
  date.setSeconds(date.getSeconds() + expire);
  document.cookie =
    c_name + "=" + escape(value) + "; path=/; expires=" + date.toGMTString();
}

//获取cookie
export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}
//HmacSHA256加密
export function encrypSHA256(password, OEM_KEY) {
  let hash = CryptoJS.HmacSHA256(password, OEM_KEY).toString();
  return hash;
}

/*删除cookie*/
export function delCookie(c_name) {
  setCookie(c_name, "", -1);
}
