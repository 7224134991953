var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portrait-main"},[_c('el-popover',{attrs:{"placement":"bottom","visible-arrow":false}},[_c('div',{staticStyle:{"display":"-webkit-flex","flex-direction":"row"},attrs:{"slot":"reference"},slot:"reference"},[_c('img',{staticClass:"portrait",attrs:{"src":_vm.account.headPhoto}}),_c('div',{staticClass:"user-name",staticStyle:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.userInfo.name)+" ")])]),_c('div',{staticStyle:{"width":"248px","padding":"8px"}},[_c('div',{staticClass:"user-detail"},[_c('img',{staticClass:"detail-portrait",attrs:{"src":_vm.account.headPhoto}}),_c('div',[_c('div',{staticClass:"detail-user-name"},[_vm._v(_vm._s(_vm.userInfo.name))]),_c('div',{staticClass:"detail-user-email"},[_vm._v(_vm._s(_vm.userInfo.email))])])]),_c('el-popover',{attrs:{"width":"260","placement":"left","trigger":"hover"}},[_c('div',_vm._l((_vm.languages),function(item){return _c('div',{key:item.dataCode,class:[
              'detail-item',
              _vm.current_language === item.dataCode && 'detail-item-active',
            ]},[_c('span',[_vm._v(_vm._s(item.dataValue))]),_c('i',{staticClass:"el-icon-check"})])}),0),_c('div',{staticClass:"menu-item",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"menu-item_left"},[_c('OEMIcon',{attrs:{"icon":"earth"}}),_c('span',{staticClass:"menu-item_text"},[_vm._v("语言")])],1),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('el-popover',{attrs:{"width":"260","placement":"left","trigger":"hover"}},[_c('div',_vm._l((_vm.userInfo.divisionList),function(item){return _c('div',{key:item.dataCode,class:[
              'detail-item',
              _vm.current_division == item.dataCode && 'detail-item-active',
            ],on:{"click":function($event){return _vm.changeDepart(item)}}},[_c('span',[_vm._v(_vm._s(item.dataValue))]),(_vm.current_division == item.dataCode)?_c('i',{staticClass:"el-icon-check"}):_vm._e()])}),0),_c('div',{staticClass:"menu-item",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"menu-item_left"},[_c('OEMIcon',{attrs:{"icon":"lock"}}),_c('span',{staticClass:"menu-item_text"},[_vm._v("权限切换")])],1),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{staticClass:"menu-item",on:{"click":_vm.goHelp}},[_c('div',{staticClass:"menu-item_left"},[_c('OEMIcon',{attrs:{"icon":"question"}}),_c('span',{staticClass:"menu-item_text"},[_vm._v("帮助中心")])],1),_c('div')]),_c('div',{staticClass:"menu-item",staticStyle:{"border":"none"},on:{"click":_vm.logout}},[_c('div',{staticClass:"menu-item_left"},[_c('OEMIcon',{attrs:{"icon":"exit"}}),_c('span',{staticClass:"menu-item_text"},[_vm._v("退出登录")])],1),_c('div')])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }