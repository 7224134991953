<template>
  <el-image
    :src="require(`@/assets/images/${icon}.png`)"
    :style="{ width: size + 'px', height: 'auto', display: 'inline-block' }"
  />
</template>

<script>
export default {
  name: "OEMIcon",
  props: {
    // 图标尺寸
    size: {
      type: Number,
      default: 24,
    },
    icon: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>
