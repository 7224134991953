<template>
  <div class="dividerTitle">
    <div style="flex: 1; display: -webkit-flex; flex-direction: row">
      <div class="divider" />
    </div>
    <div class="title">{{ text }}</div>
    <div style="flex: 1; display: -webkit-flex; flex-direction: row">
      <div class="divider" />
    </div>
  </div>
</template>

<script>
// 带文字的分割线组件
export default {
  name: "OEMDivider",
  props: {
    text: {
      type: String,
      default: "用户登录",
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.dividerTitle {
  display: -webkit-flex;
  flex-direction: row;
  align-self: center;
}
.title {
  flex: 1;
  font-size: 16px;
  justify-content: center;
  display: -webkit-flex;
  flex-direction: row;
  color: rgba(255, 255, 255, 0.6);
}

.divider {
  background-color: rgba(255, 255, 255, 0.3);
  width: 120px;
  height: 1px;
  align-self: center;
}
</style>
