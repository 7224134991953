import Vue from "vue";
import VueRouter from "vue-router";
import Storage from "@/utils/Storagehandle";
import { getCookie } from "@/utils/CookieUtils";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("@/views/help/index.vue"),
  },
  {
    path: "*",
    name: "main",
    component: () => import("@/views/index.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("main to->", to);
  //全局钩子函数
  to.matched.some((route) => {
    if (route.name === "Login") {
      next();
    } else {
      if (getCookie("accessToken")) {
        if (
          to.path === "/account/permission/list" ||
          to.path === "/account/permission/edit" ||
          to.path === "/help/list" ||
          to.path === "/help/edit"
        ) {
          const divisionId = getCookie("divisionId");
          if (divisionId === "0") {
            next();
          } else {
            next({ name: "Login", params: { path: route.path } });
          }
        } else {
          next();
        }
      } else {
        next({ name: "Login", params: { path: route.path } });
      }
    }
  });
});

export default router;
