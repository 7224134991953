<template>
  <div class="language-main">
    <span :class="textColor">简体中文</span>
    <i class="drop-down"></i>
  </div>
</template>

<script>
export default {
  name: "OEMLanguage",
  props:{
    textColor: {
      type: String,
      default: "textfff",
    },
  },

  data() {
    return {};
  },
  
};
</script>

<style lang="less" scoped>
.language-main {
  cursor: pointer;
  text-align: center;
  align-items: center;
  display: flex;
}
.textfff{
  width: 65px;
  height: 22px;
  opacity: 0.99;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-weight: 400;
  color: #fff;
}
.textblack {
  width: 65px;
  height: 22px;
  opacity: 0.99;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-weight: 400;
  color: rgba(0,0,0,0.65);
}
.drop-down {
  display: inline-block;
  width: 10px;
  height: 8px;
  margin-left: 10px;
  margin-right: 10px;
  background-image: url("../../assets/images/drop_down.png");
  background-size: 100%;
}
</style>
