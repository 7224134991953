<template>
  <div class="copy">Copyright © 1968-2022 美的集团 版权所有</div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CopyRight",
};
</script>

<style lang="less" scoped>
.copy {
  font-size: 14px;
  color: #d3d3d3;
  letter-spacing: 0;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: 15px;
}
</style>
