import config from "./config";
import Storage from "@/utils/Storagehandle";
import { getCookie } from "@/utils/CookieUtils";

export function setHeaders(
  data = {},
  ContentType = "application/json",
  responseType = ""
) {
  let sign = "";
  let random = "";
  let dataS = "";
  let OEM_KEY = "";

  dataS = typeof data === "string" ? data : JSON.stringify(data);

  if (window.location.hostname === "recipe.dollin.net") {
    OEM_KEY = config.oem_key_prod;
  } else {
    OEM_KEY = config.oem_key_sit;
  }
  random = Math.floor(Math.random() * 1000000000000 + 1);
  const str = config.eom_secret + dataS + random;
  sign = Storage.encrypSHA256(str, OEM_KEY);
  return {
    language: "zh_CN",
    random,
    sign,
    "Content-Type": ContentType,
    accessToken: getCookie("accessToken"),
    reqId: Date.now(),
    responseType: responseType,
    stamp: new Date().getTime(),
  };
}

// 将blob对象格式化为json数据
export function blob2Json(data) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader(); //创建一个FileReader实例
    reader.readAsText(data, "utf-8"); //读取文件,结果用字符串形式表示
    reader.onload = () => {
      try {
        const res = JSON.parse(reader.result);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    };
  });
}
