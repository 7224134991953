const { ROLE_PERMISSION_DATA } = require("./permissionConstants");

module.exports = {
  // functionVerifyMethodMap: {
  //   0: "云端下发产品功能",
  //   1: "设备发起功能校验",
  // },
  // productInternetMap: {
  //   0: "AP",
  //   1: "蓝牙和AP",
  // },
  appId: "1020",
  navMenuList: [
    {
      name: "分组管理",
      permitCode: ROLE_PERMISSION_DATA.GROUP_MANAGE,
      // path: "/operator/#/class/list",
      icon: "class",
      submenu: [
        {
          name: "分类管理",
          permitCode: ROLE_PERMISSION_DATA.CATEGORY_MANAGE,
          path: "/operator/#/class/list",
        },
        {
          name: "自定义标签",
          permitCode: ROLE_PERMISSION_DATA.TAG_MANAGE,
          path: "/operator/#/tag/list",
        },
      ],
    },
    {
      name: "食材管理",
      permitCode: ROLE_PERMISSION_DATA.INGREDIENT_MANAGE,
      // path: "/operator/#/stuff/list",
      icon: "stuff",
      submenu: [
        {
          name: "食材列表",
          permitCode: ROLE_PERMISSION_DATA.INGREDIENT_INFO,
          slot: "食材",
          path: "/operator/#/stuff/info/list",
        },
        {
          name: "食材分类",
          permitCode: ROLE_PERMISSION_DATA.INGREDIENT_CATEGORY,
          slot: "字典",
          path: "/operator/#/stuff/class/list",
        },
        {
          name: "禁忌宗教",
          permitCode: ROLE_PERMISSION_DATA.TABOO_RELIGION,
          path: "/operator/#/stuff/religion/list",
        },
        {
          name: "过敏源",
          permitCode: ROLE_PERMISSION_DATA.ALLERGEN,
          path: "/operator/#/stuff/allergy/list",
        },
        {
          name: "食材单位",
          permitCode: ROLE_PERMISSION_DATA.INGREDIENT_UNIT,
          path: "/operator/#/stuff/unit/list",
        },
      ],
    },
    {
      name: "食谱管理",
      permitCode: ROLE_PERMISSION_DATA.MENU_MANAGE,
      path: "/operator/#/menu/list",
      icon: "menu",
    },
    {
      name: "产品管理",
      permitCode: ROLE_PERMISSION_DATA.PRODUCT_MANAGE,
      path: "/operator/#/product/list",
      icon: "product",
    },
    {
      name: "权限管理",
      permitCode: ROLE_PERMISSION_DATA.PERMIT_MANAGE,
      icon: "class",
      submenu: [
        {
          name: "白名单管理",
          permitCode: ROLE_PERMISSION_DATA.PERMIT_MANAGE_WHITELIST,
          path: "/operator/#/account/white/list",
        },
        {
          name: "账号权限管理",
          permitCode: ROLE_PERMISSION_DATA.PERMIT_MANAGE_USER,
          path: "/operator/#/account/permission/list",
          division: ["0"],
          meta: {
            division: ["0"],
          },
        },
        {
          name: "角色管理",
          permitCode: ROLE_PERMISSION_DATA.PERMIT_MANAGE_ROLE,
          path: "/operator/#/account/role/list",
          division: ["0"],
          meta: {
            division: ["0"],
          },
        },
        {
          name: "帮助中心管理",
          path: "/operator/#/help/list",
          division: ["0"],
          meta: {
            division: ["0"],
          },
        },
      ],
    },
    // {
    //   name: "帮助中心管理",
    //   path: "/operator/#/help/list",
    //   icon: "see",
    // },
  ],
};
