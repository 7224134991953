<template>
  <div class="sidebar" :class="[collapse&&'sidebarCol']">
    <div class="sidebar_logo" @click="toProduct">
      <Logo />
    </div>
    <OEMMenu ref="menu" />
  </div>
</template>

<script>
import OEMMenu from "../OEMMenu/index";
import Logo from "../Logo/index";
export default {
  name: "SideBar",
  components: {
    OEMMenu,
    Logo,
  },
  computed:{
    collapse(){
      return this.$store.state.collapse
    }
  },
  methods: {
    toProduct() {
      this.$router.push({ path: "/menu/list" });
      // history.pushState(null, "/operator/#/menu/list", "/operator/#/menu/list");
      // console.log(this.$refs.menu);
      this.$refs.menu.setActive("/operator/#/menu/list");
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  background-image: url("../../assets/images/sidebar.png");
  background-size: 100% auto;
  background-repeat: repeat;
  background-position: top left;
  position: fixed;
  top: 0;
  width: 208px;
  bottom: 0;
  left: 0;
  width: 208px;
  z-index: 200;
  min-height: 208px;
  overflow-y: auto;
  &_logo {
    height: 73px;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }
}
.sidebarCol{
  width: 74px;
}
</style>
