import sha256 from "crypto-js/sha256";
import { indexOf } from "lodash";
import Storage from "@/utils/Storagehandle";
const CryptoJS = require("crypto-js");

export default {
  // 校验手机号码
  verifyPhoneNo(phoneNo) {
    const regexp =
      /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0-3,5-8]|18[0-9]|19[89])\d{8}$/;
    return regexp.test(phoneNo);
  },
  // 校验邮箱
  verifyEmail(email) {
    const regexp =
      /^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,6})$/;
    return regexp.test(email);
  },
  // 校验密码
  verifyPassword(password) {
    const regexp = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/;
    return regexp.test(password);
  },
  // 加密验证码
  encryptCode(password) {
    let keyIv = sha256("f526b03e0b591a6eeddf4fe77b9517fa").toString();
    let key = CryptoJS.enc.Utf8.parse(keyIv.substring(0, 16));
    let iv = CryptoJS.enc.Utf8.parse(keyIv.substring(16, 32));
    let encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  // 加密密码
  encryptPassword(password) {
    let pwd256 = sha256(password).toString();
    let keyIv = sha256("4dbc9ff6c15944d78eebb581c2b23de3").toString();
    let key = CryptoJS.enc.Utf8.parse(keyIv.substring(0, 16));
    let iv = CryptoJS.enc.Utf8.parse(keyIv.substring(16, 32));
    let encrypted = CryptoJS.AES.encrypt(pwd256, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  // 获取当前网站链接参数
  getUrlParams() {
    var url = location.search; //获取url中"?"符后的字串
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  },
  getProductSession() {
    let product_cache = sessionStorage.getItem("productSessionStorageKey");
    if (product_cache) {
      return JSON.parse(product_cache);
    } else {
      return null;
    }
  },
  //获取当前url后缀为Google或者Alexa
  getUrlHash() {
    const isOr = location.hash.indexOf("?");
    let hash = location.hash;
    if (isOr) {
      hash = hash.split("?")[0];
    }
    const newHash = hash.indexOf("google") != -1 ? "google" : "alexa";
    console.log("HASH", hash);
    return newHash;
  },
};

export const getRolePermission = (permitCode, permitCodes, isAdmin) => {
  const codes = permitCodes || Storage.getLocalItem("permitCodes");
  const admin = isAdmin || Storage.getLocalItem("isAdmin");

  if (admin === 1) {
    //超管拥有所有权限
    return true;
  }
  const permitArr = codes.split(",");
  if (indexOf(permitArr, permitCode) > -1) {
    return true;
  }
  return false;
};

export const filterByPermitCode = (arr, permitCodes, isAdmin) => {
  return arr
    .map((item) => {
      const newItem = { ...item }; // 创建一个新的菜单项
      if (item.submenu) {
        newItem.submenu = filterByPermitCode(
          item.submenu,
          permitCodes,
          isAdmin
        );
      }
      return newItem;
    })
    .filter((item) => {
      return getRolePermission(item.permitCode, permitCodes, isAdmin);
    });
};
