import http from "./axios";
import { setHeaders } from "./help";

// 封装get请求
function get(url, { ContentType = "application/json", responseType = "" }) {
  return http({
    url,
    method: "GET",
    headers: setHeaders({}, ContentType, responseType),
    data: {},
    responseType: responseType,
  });
}

// 封装post请求
function post(
  url,
  {
    data = {},
    noMsgFlag = false,
    ContentType = "application/json",
    responseType = "",
    onUploadProgress = null,
    timeout = 60 * 1000,
  }
) {
  return http({
    url,
    method: "POST",
    timeout,
    headers: setHeaders(data, ContentType, responseType),
    data,
    onUploadProgress,
    noMsgFlag,
    responseType,
  });
}

export default {
  get,
  post,
};
