// import api from "@/http";
import api from "@/http";

// 新增分类
// {
//   "dataType": "COOKING_METHOD,LANGUAGE, DISHES, SEASON, SCENES, UNIT, COOK_LEVEL, OIL_CONTENT"
//   //烹饪方式  语言  菜式  季节   场景   单位  难易度  含油量
// }
export function getSelect(params) {
  let url = "/v1/foodmenu/dict/listByDataTypes";
  return api.post(url, { data: params });
}
