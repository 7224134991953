import Storage from "@/utils/Storagehandle";
import { getCookie } from "@/utils/CookieUtils";
const state = () => ({
  account: {
    nickName: Storage.getLocalItem("nickName"),
    accessToken: getCookie("accessToken"),
    email: Storage.getLocalItem("email"),
    headPhoto: Storage.getLocalItem("headPhoto"),
    currentRoute: Storage.getItem("currentRoute") || null,
  },
  divisionId: getCookie("divisionId"), // 当前用户事业部信息，超级管理员是0
  permitCodes: Storage.getLocalItem("permitCodes"), // 当前用户所有权限
  isAdmin: Storage.getLocalItem("isAdmin"), // 当前用户所有权限
});

// actions
const actions = {
  setAccountInfo({ commit }, account) {
    commit("setAccountInfo", account);
  },
};

// mutations
const mutations = {
  setAccountInfo(state, account) {
    state.account = account;
  },
  setProductCache(state, route) {
    Storage.setItem(`currentRoute`, route);
    state.currentRoute = route;
  },
  setDivisionId(state, value) {
    state.divisionId = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
