import axios from "axios";
import { Message } from "element-ui";
import setting from "./config";
import { blob2Json, setHeaders } from "./help";
import Storage from "@/utils/Storagehandle";
// import { getCookie } from "@/utils/CookieUtils";
import {
  CODE_SUCCESS,
  CODE_TOKEN_MISSING,
  CODE_TOKEN_EXPIRED,
  CODE_TOKEN_INVALID,
  CODE_TOKEN_LOGOUT,
  CODE_NEED_LOGIN,
  CODE_NO_AUTH,
  CODE_PERMISSION_CHANGE,
} from "./code";
import { logout } from "../api/user";
import { delCookie } from "../utils/CookieUtils";

const BASE_URL = process.env.VUE_APP_API_URL;
//解决一个页面弹框多次的问题
// let msgBool = true;

// 创建axios实例
const http = axios.create({
  baseURL: BASE_URL,
  timeout: setting.timeout, // 请求超时时间
});

// 添加request请求拦截器
http.interceptors.request.use(
  (config) => {
    const language = "zh_CN";
    config.headers = {
      ...config.headers,
      appId: setting.appId,
      language,
    };

    if (
      !config.headers.accessToken &&
      config.url !== "/v1/user/developer/login"
    ) {
      window.location.href = "/#/login";
    } else {
      return config;
    }
  },
  (error) => {
    Promise.reject(error);
  }
);

// 添加response响应拦截器
http.interceptors.response.use(
  async (response) => {
    if (Object.prototype.toString.call(response.data).slice(8, -1) === "Blob") {
      if (response.data.type === "application/json") {
        response.data = await blob2Json(response.data);
      } else {
        return Promise.resolve(response);
      }
    }

    const config = response.config;
    const code = response.data.code;
    const tokenExtendURL = "/v1/user/developer/token/extend";
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", code, response);
    if (code === CODE_SUCCESS) {
      if (config.url === tokenExtendURL) {
        return Promise.resolve(response.data);
      }
      return Promise.resolve(response.data.data);
    } else {
      // token过期，静默更新token并重发业务接口
      if (code === CODE_TOKEN_EXPIRED) {
        const res = await http({
          url: tokenExtendURL,
          method: "POST",
          headers: setHeaders(),
          // data: {},
          // onUploadProgress: null,
          // noMsgFlag: false,
          // responseType: "",
        });

        if (res.code === CODE_SUCCESS) {
          Storage.setLocalItem("accessToken", res.data.accessToken);

          return new Promise((resolve, reject) => {
            try {
              resolve(
                http({
                  ...config,
                  headers: setHeaders(
                    config.data,
                    config.headers["Content-Type"],
                    config.responseType
                  ),
                })
              );
            } catch (error) {
              reject(error);
            }
          });
        } else {
          return Promise.reject(res);
        }
      } else if (
        code === CODE_TOKEN_MISSING ||
        code === CODE_TOKEN_INVALID ||
        code === CODE_TOKEN_LOGOUT ||
        // code === CODE_NEED_LOGIN ||
        code === CODE_NO_AUTH ||
        code === CODE_PERMISSION_CHANGE
      ) {
        if (response.config.url !== "/v1/user/logout") {
          Message.error(response.data.msg);
        }
        // 暂时注释
        window.location.href = "/#/login";
      } else if (code === CODE_NEED_LOGIN) {
        logout()
          .then((res) => {
            console.log(res);
            delCookie("accessToken"); // 删除本地Cookie

            // 登出的地址，只分生产环境和非生产环境
            let redirect_uri = window.location.origin;
            let host = "";
            if (
              window.location.host === "recipe.dollin.net" ||
              window.location.host === "uat-recipe.dollin.net"
            ) {
              host = "https://signin.midea.com";
            } else {
              host = "https://signinuat.midea.com";
            }
            window.location.href = `${host}/logout?service=${redirect_uri}`;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("");
      }

      // TODO
      // if (!CODE_NOT_MSG.includes(code) && !response.config.noMsgFlag) {
      Message({
        type: "error",
        message: response.data.msg,
      });
      // }

      return Promise.reject(response.data);
    }
  },
  (error) => {
    Message.error("系统异常，请联系平台管理人员");
    return Promise.reject(error);
  }
);

export default http;
